<template> 

  <div class="pbf">
  <template v-if="brand_nutrient_data?.id">

    <BrandHead 
      :id="brand_nutrient_data.id"
      :brand_package="brand_nutrient_data.brand_package"
      :nm="brand_nutrient_data.name"
      :nmalt="brand_nutrient_data.name"
      :logo="brand_nutrient_data.logo_m"
      :link="brand_nutrient_data.section"
      :rate="brand_nutrient_data?.item_stat?.avg_rate"
      :slogan="brand_nutrient_data.slogan"
      :background="brand_nutrient_data.background"
      :cover="brand_nutrient_data.cover"
      :cover_mobile="brand_nutrient_data.cover_mobile"
      :cnt_followers="brand_nutrient_data?.item_stat?.cnt_followers"
      :isfollow="brand_nutrient_data?.is_follow"
      :type="type"
      :offical_user="brand_nutrient_data?.offical_user"
      :bread="[
        {name: $t('nutrients_view_link_text'), link: '/nutrients'},
        {name: brand_nutrient_data.name, link: brand_nutrient_data.section},
      ]"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >

    <UiTabs 
      :tabs="getTabs"
      :acttab="active_tab"
      />    
    </BrandHead>

    <div class="pb grid_width">    
      <NuxtPage 
        :data="brand_nutrient_data"
        :id="brand_nutrient_data.id"
        />
    </div>
 
    <BrandContextMenu 
      :is-follow="brand_nutrient_data?.is_follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />

    </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 

  </div>
</template>
 
<script setup lang="ts">

const route = useRoute();
const router = useRouter();
const {$api, $follow, $popup} = useNuxtApp()
var id = ref(route.params['id']);
var active_tab = ref('info');


const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: brand_nutrient_data.value.link + '',          
    count: 0,
    icon: '',
  });

  if(brand_nutrient_data.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: brand_nutrient_data.value.link + '/diaries',
      count: brand_nutrient_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }

  if(brand_nutrient_data.value?.item_stat?.cnt_growers){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: brand_nutrient_data.value.link + '/growers',
      count: brand_nutrient_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(brand_nutrient_data.value?.item_stat?.cnt_items){
    items.push({
      id: 'products',
      name: 'Products',
      link: brand_nutrient_data.value.link + '/products',
      count: brand_nutrient_data.value?.item_stat?.cnt_items,
      icon: '',
    });
  }

  if(brand_nutrient_data.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: brand_nutrient_data.value.link + '/reviews',
      count: brand_nutrient_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  if(brand_nutrient_data.value?.item_stat?.cnt_schemes){
    items.push({
      id: 'schemes',
      name: 'Feeding',
      link: brand_nutrient_data.value.link + '/schemes',
      count: brand_nutrient_data.value?.item_stat?.cnt_schemes,
      icon: '',
    });
  }

  return items;

 
})
 
const follow = function(state) {  
  $follow.toggle('brand', brand_nutrient_data.value.id, state, {
    type: 'brand',
    id: brand_nutrient_data.value.id,
    avatar: brand_nutrient_data.value.avatar_small, 
    name: brand_nutrient_data.value.name, 
    link: brand_nutrient_data.value.link, 
  })
  .then((response) => {    
    brand_nutrient_data.value.is_follow = state;
    if(brand_nutrient_data.value.item_stat)
      brand_nutrient_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error' + error)
  });
}
 

const loadData = async function(load_id) {     
  const response = await $api.getBrandBySectionCategory(load_id, 'nutrient')  
  return response;  
}


const { pending: is_loading,  data: brand_nutrient_data } = await useLazyAsyncData('brand_nutrient_data_' + id.value, async () => await loadData(id.value))

 
watch( () => brand_nutrient_data, () => {
  useHead(useNuxtApp().$head.getBrandNutrient(brand_nutrient_data.value));
}, {immediate: true, deep: true});
 
watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getBrandNutrient(brand_nutrient_data.value));
}, {immediate: true, deep: true});
 

onBeforeUnmount(() => {
  brand_nutrient_data.value = null;
})

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>